import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const IntroText = ({ children }) => {
    return <p className={cx('text-21 leading-snug mb-24')}>{children}</p>
}

IntroText.propTypes = {
    children: PropTypes.node,
}

export default IntroText
