import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IntroText from '../components/IntroText'
import Content, { HTMLContent } from '../components/Content'

export const BasicPageTemplate = ({
    title,
    subHeading,
    content,
    contentComponent,
}) => {
    const PageContent = contentComponent || Content

    return (
        <section
            className={cx('max-w-1264 mx-auto px-24 lg:px-32 py-60 lg:pb-120')}
        >
            <h1 className={cx('hd-jb mb-32 text-purple-700')}>{title}</h1>

            {subHeading && <IntroText>{subHeading}</IntroText>}

            <PageContent className="rich-text" content={content} />
        </section>
    )
}

BasicPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const BasicPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout
            title={post.frontmatter.title}
            description={post.frontmatter.subHeading}
        >
            <BasicPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                subHeading={post.frontmatter.subHeading}
                content={post.html}
            />
        </Layout>
    )
}

BasicPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default BasicPage

export const basicPageQuery = graphql`
    query BasicPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                subHeading
            }
        }
    }
`
